import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Zermatt in Switzerland is surrounded by the high mountains of the main ridge
of the alps. One of the most famous ones, the Matterhorn, always in sight,
the trails wind down from high alpine areas like the over 3.000m high
Gornergrat.`}</p>
    <br />
    <p>{`Over rocky and technical terrain there are long descents with
1.400vm down to the idyllic mountain village. The access is easy and the many
lifts and trains take you and your bike up.`}</p>
    <br />
    <p>{`Rarely we have experienced such a
density of fantastic, long, natural single tracks like here in Zermatt.
Especially the trails lower down, below the tree line are awesome and offer
with their natural rhythm more flow than any built trail could.`}</p>
    <br />
    <p>{`In 2019 the
trails will proof also their racing qualities with one stop of the Enduro
World Series and already this year you can compete in the European Enduro
Series in Zermatt`}</p>
    <TrailguidePlugin content="lat=46.0172&lng=7.7531&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.0172,7.7531"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Gornergratsee`}</h4>
    <p>{`The Gornergrat train spits you out in over 3000m. On this technical descent
you have a great view on the most famous mountain of the alps.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2244"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/zermatt_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Japanese Highway`}</h4>
    <p>{`Also from the Gornergrat, the trail with this funny name starts. When you
are at the top, you will know why ;-).
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2242"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/zermatt_5.jpg" mdxType="Image" />
    <br />
    <p>{`But it's really worth to survive the first touristic meters. You will be
rewarded with an awesome trail.`}</p>
    <Image src="destinations/zermatt_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Hobbit Trail`}</h4>
    <p>{`On the other side of the valley you can ride amazing flowy tails like the Hobbit Trail.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2238"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/zermatt_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Europaweg`}</h4>
    <p>{`Zermat is only accessible by train. On the way back to Täsch you have a way better option
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1962"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/zermatt_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Militärweg`}</h4>
    <p>{`It's really worth to extend the Europaweg with the Militärweg. After
some climbing and traversing, one of our favourite descent starts.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2240"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/zermatt_2.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      